import React from 'react';
import { Row, Col } from 'reactstrap';
import ScrollspyNav from './scrollSpy';

class Home1 extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section home-2-bg" id="home">
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <Row className="align-items-center">
                                    <Col lg="5">
                                        <ScrollspyNav
                                            scrollTargetIds={["home","about","features","clients"]} // be sure to change this while you add more sections in the home page & add scroll spy if you want to add more items
                                            activeNavClass="active"
                                            scrollDuration="800"
                                            headerBackground="true"
                                            className="ml-auto"> 
                                            <div className="mt-40 home-2-content">
                                                <h1 className="text-white font-weight-normal home-2-title display-1 mb-0">Sophys</h1>
                                                <h2 className="text-white-70 mt-4 f-21 mb-0"> AI-Powered Medical Coding </h2>
                                                <div className="mt-5">
                                               <a href="https://forms.gle/yiiKjG8khzaFDJ6q9" className="btn btn-custom mr-4" > Get in touch </a>
                                                </div>
                                            </div>
                                        </ScrollspyNav> 
                                    </Col>

                                    <Col lg="7">
                                        <div className="mt-40 home-2-content position-relative">
                                            <img src="images/dataproc.png" alt="" className="img-fluid mx-auto d-block home-2-img mover-img" />
                                            <div className="home-2-bottom-img">
                                                <img src="images/dataproc.png" alt="" className="img-fluid d-block mx-auto" />
                                            </div>
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default Home1;