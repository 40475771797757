import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class AboutSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-lightbulb",
                "title": "Creative Design",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            }, {
                "icon": "mdi mdi-projector-screen",
                "title": "Strategy Solutions",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Dynamic Growth",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="about">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 f-22 font-weight-light text-uppercase"> Solution </h3>
                                </div>
                            </Col>
                        </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="about-desc">
                                    <h3 className="font-weight-light text-muted "> Sophys automates and significantly improves CPT, ICD10-CM & HCPCS coding accuracy, saves time and reduces total cost of coding for healthcare providers.</h3>
                                </div>
                            </Col>

                            <Col md="5">
                                <div className="position-relative p-5">
                                    <img src="images/Doct.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutSection;