import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';


class ContactSection extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            name:"",
            email:"",
            subject:"",
            comments:""
        };

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleValidSubmit(event) {
        event.persist();
        console.log(this.state);
        emailjs.sendForm('service_8qjd6oj', 'template_ag2lstd', event.target, 'user_2JJrlPXXvZqYu0wx0EVql');
        event.target.reset();
    }

    handleInvalidSubmit(event, errors, values) {
        this.setState({ email: values.email, error: true });
    }

    handleChange(event){
        event.persist();
        this.setState({[event.target.name]: event.target.value});
    }

    resetForm() {
        this.setState({
          name: "",
          email: "",
          subject: "",
          comments: "",
        });
      }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-light" id="contact">
                    <div className="container">
                        <div className="row">
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Get in touch</h3>
                                </div>
                            </Col>
                        </div>

                        <Row>
                            <Col lg="12">
                                <div className="contact-box p-5">
                                    <Row>
                                        <Col lg="8" md="6">
                                            <div className="custom-form p-3">
                                                <div id="message"></div>
                                                <AvForm id="contact-form" onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="form-group app-label">
                                                                <AvField name="name" type="text" validate={{ required: { value: true } }} placeholder="Name" onChange={ this.handleChange} />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="form-group app-label">
                                                                <AvField name="email" type="text" errorMessage="Invalid Email" validate={{ required: { value: true }, email: { value: true } }} placeholder="Email" onChange={this.handleChange} />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <div className="form-group app-label">
                                                                <AvField name="subject" type="text" validate={{ required: { value: true } }} placeholder="Subject" onChange={ this.handleChange} />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <div className="form-group app-label">
                                                                <AvField name="comments" type="textarea" validate={{ required: { value: true } }} rows="5" placeholder="Message" onChange={ this.handleChange} />
                                                            </div>
                                                        </Col>
                                                        <Row>
                                                            <Col sm="12">
                                                                &nbsp; &nbsp;  <input type="submit" className="submitBnt btn btn-custom" value="Send Message" />
                                                                <div id="simple-msg"></div>
                                                            </Col>
                                                        </Row>

                                                    </Row>
                                                </AvForm>
                                            </div>
                                        </Col>

                                        <div className="col-lg-4 col-md-6">
                                            <div className="contact-cantent p-3">
                                                <div className="follow mt-4">
                                                    <h4 className="text-dark mb-3">Follow Us</h4>
                                                    <ul className="follow-icon list-inline mt-32 mb-0">
                                                        <li className="list-inline-item f-8"><Link to="#" className="social-icon text-muted"><i className="mdi mdi-facebook"></i></Link></li>&nbsp;
                                                        <li className="list-inline-item f-8"><Link to="#" className="social-icon text-muted"><i className="mdi mdi-twitter"></i></Link></li>&nbsp;
                                                        <li className="list-inline-item f-8"><Link to="#" className="social-icon text-muted"><i className="mdi mdi-linkedin"></i></Link></li>&nbsp;
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ContactSection;