import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class FeatureSection extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section bg-features bg-light" id="features">
                    <div className="container">
                        <Row>
                            <Col className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase f-22">Company</h3>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Analyze 280 Million Patient Records in Minutes</h4>
                                    <p className="text-muted f-16"> Our supercharged search technology combined with AI, extracts millions of data points in minutes. Choose our tools to then visualize your data and perform your analysis seamlessly. </p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-32">
                                    <img src="images/features-img/online.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row> */}
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-img">
                                    <img src="images/features-img/team.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="about-desc">        
                                    <h3 className="font-weight-light text-muted">Sophys is built by expert medical coders, clinicians and engineers.</h3> 
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                {/* <section className="section bg-features">
                    <div className="container">
                        
                    </div>
                </section> */}

                {/* <section className="section bg-features bg-light">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
        
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4"> Preserve and Secure Data</h4>
                                    <p className="text-muted f-16"> Your data and analysis stays secure and preserved within your own network, easily accessible by your research team.</p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-40">
                                    <img src="images/features-img/safe.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section> */}
            </React.Fragment>
        );
    }
}
export default FeatureSection;